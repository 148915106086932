<template lang="pug">
v-card(class="px-5 pb-5")
  v-card-title(class="px-0")
    span(class="text-h5 font-weight-bold") {{ detailMode === 'edit' ? '編輯' : '新增' }}
  v-form(@submit.prevent="submit" 
    ref="form"
    v-model="valid"
    lazy-validation)
    v-row
      v-col(cols="12")
        Group(:searchGroupId="innerData.groupId" @groupId="handleSearchGroup")
      v-col(cols="6")
        v-select(
          v-model="innerData.identityType" 
          :items="identityOptions"
          label="身分"
          :rules="rules"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="innerData.name" 
          label="姓名"
          :rules="rules"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="innerData.introducer" 
          label="介紹人姓名"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="innerData.phone" 
          label="手機"
          :rules="rules"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="innerData.email" 
          label="Email"
          hide-details
        )
      v-col(cols="6")
        v-radio-group(
          v-model="innerData.gender"
          row
          hide-details
        )
          template(v-slot:label)
            span 性別
          v-radio(label="男" value="男")
          v-radio(label="女" value="女")
      v-col(cols="12")
        v-textarea(
          v-model="innerData.memo" 
          label="備註"
          hide-details
        )
      v-col(cols="6")    
        v-select(
          v-model="innerData.eventTicketId" 
          :items="ticketOptions"
          label="票價"
          :rules="rules"
          hide-details
        )
      v-col(cols="6")
        v-text-field(
          v-model="innerData.serialNumberText" 
          label="序號"
          :rules="rules"
          hide-details
        )
      v-col(cols="6")
        v-radio-group(
          v-model="innerData.christianText"
          row
          hide-details
        )
          v-radio(label="基督徒" value="基督徒")
          v-radio(label="慕道友" value="慕道友")
      v-col(cols="6")
        v-radio-group(
            v-model="innerData.marriageText"
            row
            hide-details
          )
          v-radio(label="單身" value="單身")
          v-radio(label="已婚" value="已婚")
          v-radio(label="其他" value="其他")
    v-row
      v-col(class="d-flex justify-center")
          v-btn(
            type="submit"
            class="ma-2 font-weight-bold text-button" dark color="primary")
            v-icon(dark) mdi-save
            span(class="font-weight-bold") 儲存
      
          v-btn(
            @click.stop="handleCancel"
            class="ma-2 ml-5 font-weight-bold text-button")
            span(class="font-weight-bold") 取消
</template>

<script>
import Group from "@/components/common/group";
export default {
  name: "innerDetailForm",
  components: {
    Group,
  },
  props: {
    detailMode: {
      type: String,
    },
    editItem: {
      type: Object,
    },
    identityOptions: {
      type: Array
    },
    ticketOptions: {
      type: Array
    }
  },
  data() {
    return {
      valid: true,
      rules: [
        v => !!v || '必填',
      ],
      innerData: {
        groupId: null,
        identityType: null,
        name: null,
        introducer: null,
        phone: null,
        email: null,
        gender: null,
        memo: null,
        serialNumberText: null,
        christianText: null,
        marriageText: null,
        eventTicketId: null,
      },
    };
  },
  watch: {
    editItem(val) {
      if (this.detailMode === 'edit') {
        this.innerData = val
      }
    }
  },
  methods: {
    handleSearchGroup(val) {
      this.innerData.groupId = val
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.innerData);
      }
    },
    handleCancel() {
      this.innerData = {}
      this.$refs.form.reset();
      this.$emit("cancel");
    }
  },
  created() {
    if (this.detailMode === 'edit') {
      this.innerData = this.editItem
    }   
  }
};
</script>
