<template lang="pug">
v-container(fluid)
  div(class="d-flex justify-space-between mb-2")
    h2(class="blue-grey--text")
      v-icon(class="mr-1") mdi-home-variant-outline
      span 本堂繳費管理
  SearchForm(
    :loading="exporting"
    @add="handleOpenDetailDialog"
    @search="getList"
    @export="handleExport"
  )
  v-divider(class="my-4")
  h4(class="my-2") 查詢共 {{ listData.length }} 筆
  SearchTable(
    :loading="loading"
    :dataList="listData"
    @delete="handleDeleteItem"
    @edit="handleOpenDetailDialog"
  )
  v-dialog(
    v-model="detailDialog"
    width="600"
    persistent
  )
    DetailForm(
      :identityOptions="identityOptions"
      :ticketOptions="ticketOptions"
      :detailMode="detailMode"
      :editItem="editItem"
      @cancel="handleCloseDetailDialog"
      @submit="handleSubmit"
    )
</template>

<script>
import SearchForm from "@/components/inner/searchForm";
import SearchTable from "@/components/inner/searchTable";
import DetailForm from '@/components/inner/detailForm';
export default {
  name: "innerSearch",
  components: {
    SearchForm,
    SearchTable,
    DetailForm,
  },
  data: () => ({
    analyzeData: {},
    identityOptions: [],
    ticketOptions: [],
    listData: [],
    loading: false,
    exporting: false,
    detailMode: null,
    detailDialog: false,
    editItem: {},
  }),
  methods: {
    async getClcGroupSelect() {
      try {
        let groupRes = await this.$api.common.commonAPI.getClcGroupList();
        if (groupRes && groupRes.data.code === 200) {
          this.$store.dispatch("group/setGroup", groupRes.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getIdentityType() {
      try {
        let identityRes = await this.$api.common.commonAPI.getIdentityType();
        this.identityOptions = identityRes.data.content;
      } catch (err) {
        console.log(err);
      }
    },
    async getSelectTicket() {
      try {
        let ticketRes = await this.$api.common.commonAPI.getSelectTicket();
        this.ticketOptions = ticketRes.data.content;
      } catch (err) {
        console.log(err);
      }
    },
    async getClcList() {
      try {
        let clcListRes = await this.$api.common.commonAPI.getClcList();
        if (clcListRes && clcListRes.data.code === 200) {
          this.$store.dispatch("group/setClcList", clcListRes.data.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getList(payload) {
      try {
        this.loading = true;
        let listRes = await this.$api.inner.innerAPI.getList(
          payload
        );
        if (listRes && listRes.data.code === 200) {
          this.listData = listRes.data.content;
        }
        if (!listRes.data.success && listRes.data.showalert) {
          this.$store.dispatch("notification/openNotification", {
            msg: listRes.data.message,
            color: "red",
            timeout: 5000,
            showClose: false,
          });
          if (listRes.data.isRedirect) {
            this.$router.push("/permissions");
          }
        }
        this.loading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async handleExport(searchPayload) {
      try {
        this.exporting = true;
        let res = await this.$api.inner.innerAPI.exportList(searchPayload);
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.click()
        link.remove()
        this.exporting = false;
        this.$store.dispatch("notification/openNotification", {
          msg: `匯出成功`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
      } catch (err) {
        console.log(err);
      }
    },
    handleOpenDetailDialog (item) {
      this.detailMode = item ? 'edit' : 'add'
      this.detailDialog = true
      this.editItem = item
    },
    handleCloseDetailDialog () {
      this.detailDialog = false
      this.editItem = {}
    },
    handleSubmit (item) {
      if (this.detailMode === 'add') {
        this.handleAddItem(item)
      }
      if (this.detailMode === 'edit') {
        this.handleUpdate(item)
      }
    },
    async handleUpdate(item) {
      try {
        const updateData = {
          id: item.id,
          groupId: item.groupId,
          identityType: item.identityType,
          name: item.name,
          introducer: item.introducer,
          phone: item.phone,
          email: item.email,
          gender: item.gender,
          memo: item.memo,
          church: item.locationText,
          serialNumberText: item.serialNumberText,
          christianText: item.christianText,
          marriageText: item.marriageText
        }
        await this.$api.inner.innerAPI.modifyInner(updateData);
        this.$store.dispatch("notification/openNotification", {
          msg: `資料已更新`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.detailMode = null;
        this.detailDialog = false
        this.getList({
          registrationDate: "",
          serialNumber: "",
          serialNumberText: "",
          name: "",
          phone: ""
        });
      } catch (err) {
        console.log(err);
      }
    },

    async handleAddItem(item) {
      try {
        const addData = {
          ...item
        }
        await this.$api.inner.innerAPI.addInner(addData);
        this.$store.dispatch("notification/openNotification", {
          msg: `資料已新增`,
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.detailDialog = false
        this.detailMode = null;
        this.getList({
          registrationDate: "",
          serialNumber: "",
          serialNumberText: "",
          name: "",
          phone: ""
        });
      } catch (err) {
        console.log(err);
      }
    },

    async handleDeleteItem(item) {
      try {
        await this.$api.inner.innerAPI.deleteInner({
          id: item.id,
        });
        this.$store.dispatch("notification/openNotification", {
          msg: "已刪除",
          color: "green",
          timeout: 5000,
          showClose: false,
        });
        this.getList({
          registrationDate: "",
          serialNumber: "",
          serialNumberText: "",
          name: "",
          phone: ""
        });
      } catch (err) {
        console.log(err);
      }
    },

  
  },
  created() {
    this.getClcList();
    this.getClcGroupSelect();
    this.getIdentityType();
    this.getSelectTicket();
    this.getList({
      registrationDate: "",
      serialNumber: "",
      serialNumberText: "",
      name: "",
      phone: ""
    }); 
  },
};
</script>
